const cards = () => {

  var $owl = $('.loop');

  $owl.owlCarousel({
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 4000,
    autoplaySpeed: 800,
    center: true,
    items: 3,
    stagePadding: 15,
    loop: true,
    nav: true,
    animateOut: 'slide-up',
    animateIn: 'slide-down',

    responsive: {
      // breakpoint from 0 up
      0: {
        margin: 15,
        items: 1.3,
      },
      992: {
        margin: 30,
        items: 3,
        nav: true
      },
      1200: {
        margin: 50,
        items: 3,
        nav: true
      },
      1600: {
        margin: 50,
        items: 3,
        nav: true
      }
    }
  });
}

export default cards;
