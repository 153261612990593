// Шаблон проверки и отправки формы


const validate = () => {
  const forms = document.querySelectorAll("form");

  forms.forEach((form, index) => {
    var box = form.closest(".modal__inner");

    if (!box) {
      box = form.closest(".form-animate");
    }

    form.addEventListener("submit", formSend);

    async function formSend(e) {
      e.preventDefault();

      let error = formValidate(form);
      let formData = new FormData(form);

      if (error === 0) {
        let response = await fetch("mail.php", {
          method: "POST",
          body: formData
        });
      } else {
        console.log("Заполните поля");
      }
    }

    function formValidate(form) {
      let error = 0;
      let formReq = form.querySelectorAll("._req");

      for (let index = 0; index < formReq.length; index++) {
        const input = formReq[index];
        formRemoveError(input);

        // Проверка поля email, chekbox(согласия на обработку данных) и текстовых полей
        if (input.classList.contains("_email")) {
          if (emailTest(input)) {
            formAddError(input);
            error++;
          }
        } else {
          if (input.value === "") {
            formAddError(input);
            error++;
          }
        }
      }

      return error;
    }

    function formAddError(input) {
      input.classList.add("_error");
    }

    function formRemoveError(input) {
      input.classList.remove("_error");
    }

    // Функция теста Email
    function emailTest(input) {
      return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(input.value);
    }
  });
}

export default validate;
